:root {
  --spacing-0: 0px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;
  --spacing-14: 56px;
  --spacing-16: 64px;
  --spacing-20: 80px;
  --spacing-24: 96px;
  --spacing-28: 112px;
  --spacing-32: 128px;
  --spacing-36: 144px;
  --spacing-40: 160px;
  --spacing-44: 176px;
  --spacing-48: 192px;
  --spacing-52: 208px;
  --spacing-56: 224px;
  --spacing-60: 240px;
  --spacing-64: 256px;
  --spacing-72: 288px;
  --spacing-80: 320px;
  --spacing-96: 384px;
  --spacing-px: 1px;
  --spacing-0-5: 2px;
  --spacing-1-5: 6px;
  --spacing-2-5: 10px;
  --spacing-3-5: 14px;

  --width-w-0: 0px;
  --width-w-px: 1px;
  --width-w-0-5: 2px;
  --width-w-1: 4px;
  --width-w-1-5: 6px;
  --width-w-2: 8px;
  --width-w-2-5: 10px;
  --width-w-3: 12px;
  --width-w-3-5: 14px;
  --width-w-4: 16px;
  --width-w-5: 20px;
  --width-w-6: 24px;
  --width-w-7: 28px;
  --width-w-8: 32px;
  --width-w-9: 36px;
  --width-w-10: 40px;
  --width-w-11: 44px;
  --width-w-12: 48px;
  --width-w-14: 56px;
  --width-w-16: 64px;
  --width-w-20: 80px;
  --width-w-24: 96px;
  --width-w-28: 112px;
  --width-w-32: 128px;
  --width-w-36: 144px;
  --width-w-44: 176px;
  --width-w-48: 192px;
  --width-w-52: 208px;
  --width-w-56: 224px;
  --width-w-64: 256px;
  --width-w-72: 288px;
  --width-w-80: 320px;
  --width-w-96: 384px;

  --height-h-0: 0px;
  --height-h-px: 1px;
  --height-h-0-5: 2px;
  --height-h-1: 4px;
  --height-h-1-5: 6px;
  --height-h-2: 8px;
  --height-h-2-5: 10px;
  --height-h-3: 12px;
  --height-h-3-5: 14px;
  --height-h-4: 16px;
  --height-h-5: 20px;
  --height-h-6: 24px;
  --height-h-7: 28px;
  --height-h-8: 32px;
  --height-h-9: 36px;
  --height-h-10: 40px;
  --height-h-11: 44px;
  --height-h-12: 48px;
  --height-h-14: 56px;
  --height-h-16: 64px;
  --height-h-20: 80px;
  --height-h-24: 96px;
  --height-h-28: 112px;
  --height-h-32: 128px;
  --height-h-36: 144px;
  --height-h-44: 176px;
  --height-h-48: 192px;
  --height-h-52: 208px;
  --height-h-56: 224px;
  --height-h-64: 256px;
  --height-h-72: 288px;
  --height-h-80: 320px;
  --height-h-96: 384px;

  --breakpoint-sm: 640px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
  --breakpoint-2xl: 1536px;

  --radius-rounded-none: 0px;
  --radius-rounded-sm: 2px;
  --radius-rounded: 4px;
  --radius-rounded-md: 6px;
  --radius-rounded-lg: 8px;
  --radius-rounded-xl: 12px;
  --radius-rounded-2xl: 16px;
  --radius-rounded-3xl: 24px;
  --radius-rounded-4xl: 32px;
  --radius-rounded-full: 9999px;

  --border-width-border-0: 0px;
  --border-width-border-1: 1px;
  --border-width-border-2: 2px;
  --border-width-border-4: 4px;
  --border-width-border-8: 8px;

  --max-width-max-w-0: 0px;
  --max-width-max-w-3xs: 256px;
  --max-width-max-w-2xs: 288px;
  --max-width-max-w-xs: 320px;
  --max-width-max-w-sm: 384px;
  --max-width-max-w-md: 448px;
  --max-width-max-w-lg: 512px;
  --max-width-max-w-xl: 576px;
  --max-width-max-w-2xl: 672px;
  --max-width-max-w-3xl: 768px;
  --max-width-max-w-4xl: 896px;
  --max-width-max-w-5xl: 1024px;
  --max-width-max-w-6xl: 1152px;
  --max-width-max-w-7xl: 1280px;

  --opacity-opacity-0: 0;
  --opacity-opacity-5: 5%;
  --opacity-opacity-10: 10%;
  --opacity-opacity-15: 15%;
  --opacity-opacity-20: 20%;
  --opacity-opacity-25: 25%;
  --opacity-opacity-30: 30%;
  --opacity-opacity-35: 35%;
  --opacity-opacity-40: 40%;
  --opacity-opacity-45: 45%;
  --opacity-opacity-50: 50%;
  --opacity-opacity-55: 55%;
  --opacity-opacity-60: 60%;
  --opacity-opacity-65: 65%;
  --opacity-opacity-70: 70%;
  --opacity-opacity-75: 75%;
  --opacity-opacity-80: 80%;
  --opacity-opacity-85: 85%;
  --opacity-opacity-90: 90%;
  --opacity-opacity-95: 95%;
  --opacity-opacity-100: 100%;

  --blur-blur-none: 0px;
  --blur-blur-sm: 4px;
  --blur-blur: 8px;
  --blur-blur-md: 12px;
  --blur-blur-lg: 16px;
  --blur-blur-xl: 24px;
  --blur-blur-2xl: 40px;
  --blur-blur-3xl: 64px;

  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;

  --font-letter-spacing-tighter: -0.8px;
  --font-letter-spacing-tight: -0.4px;
  --font-letter-spacing-normal: 0px;
  --font-letter-spacing-wide: 0.4px;
  --font-letter-spacing-wider: 0.8px;
  --font-letter-spacing-widest: 1.6px;

  --font-line-height-leading-3: 12px;
  --font-line-height-leading-4: 16px;
  --font-line-height-leading-5: 20px;
  --font-line-height-leading-6: 24px;
  --font-line-height-leading-7: 28px;
  --font-line-height-leading-8: 32px;
  --font-line-height-leading-9: 36px;
  --font-line-height-leading-10: 40px;

  --font-size-text-xs: 12px;
  --font-size-text-sm: 14px;
  --font-size-text-base: 16px;
  --font-size-text-lg: 18px;
  --font-size-text-xl: 20px;
  --font-size-text-2xl: 24px;

  --font-size-text-3xl: 30px;
  --font-size-text-4xl: 36px;
  --font-size-text-5xl: 48px;
  --font-size-text-6xl: 60px;
  --font-size-text-7xl: 72px;
  --font-size-text-8xl: 96px;
  --font-size-text-9xl: 128px;
  --tailwind-colors-slate-50: #f8fafc;
  --tailwind-colors-slate-100: #f1f5f9;
  --tailwind-colors-slate-200: #e2e8f0;
  --tailwind-colors-slate-300: #cbd5e1;
  --tailwind-colors-slate-400: #94a3b8;
  --tailwind-colors-slate-500: #64748b;
  --tailwind-colors-slate-600: #475569;
  --tailwind-colors-slate-700: #334155;
  --tailwind-colors-slate-800: #1e293b;
  --tailwind-colors-slate-900: #0f172a;
  --tailwind-colors-slate-950: #020617;
  --tailwind-colors-base-black: #000000;
  --tailwind-colors-base-white: #ffffff;
  --tailwind-colors-base-transparent: rgba(255, 255, 255, 0);
  --tailwind-colors-gray-50: #f9fafb;
  --tailwind-colors-gray-100: #f3f4f6;
  --tailwind-colors-gray-200: #e5e7eb;
  --tailwind-colors-gray-300: #d1d5db;
  --tailwind-colors-gray-400: #9ca3af;
  --tailwind-colors-gray-500: #6b7280;
  --tailwind-colors-gray-600: #4b5563;
  --tailwind-colors-gray-700: #374151;
  --tailwind-colors-gray-800: #1f2937;
  --tailwind-colors-gray-900: #111827;
  --tailwind-colors-gray-950: #030712;
  --tailwind-colors-zinc-50: #fafafa;
  --tailwind-colors-zinc-100: #f4f4f5;
  --tailwind-colors-zinc-200: #e4e4e7;
  --tailwind-colors-zinc-300: #d4d4d8;
  --tailwind-colors-zinc-400: #a1a1aa;
  --tailwind-colors-zinc-500: #71717a;
  --tailwind-colors-zinc-600: #52525b;
  --tailwind-colors-zinc-700: #3f3f46;
  --tailwind-colors-zinc-800: #27272a;
  --tailwind-colors-zinc-900: #18181b;
  --tailwind-colors-zinc-950: #09090b;
  --tailwind-colors-neutral-50: #fafafa;
  --tailwind-colors-neutral-100: #f5f5f5;
  --tailwind-colors-neutral-200: #e5e5e5;
  --tailwind-colors-neutral-300: #d4d4d4;
  --tailwind-colors-neutral-400: #a3a3a3;
  --tailwind-colors-neutral-500: #737373;
  --tailwind-colors-neutral-600: #525252;
  --tailwind-colors-neutral-700: #404040;
  --tailwind-colors-neutral-800: #262626;
  --tailwind-colors-neutral-900: #171717;
  --tailwind-colors-neutral-950: #0a0a0a;
  --tailwind-colors-stone-50: #fafaf9;
  --tailwind-colors-stone-100: #f5f5f4;
  --tailwind-colors-stone-200: #e7e5e4;
  --tailwind-colors-stone-300: #d6d3d1;
  --tailwind-colors-stone-400: #a8a29e;
  --tailwind-colors-stone-500: #78716c;
  --tailwind-colors-stone-600: #57534e;
  --tailwind-colors-stone-700: #44403c;
  --tailwind-colors-stone-800: #292524;
  --tailwind-colors-stone-900: #1c1917;
  --tailwind-colors-stone-950: #0c0a09;
  --tailwind-colors-red-50: #fef2f2;
  --tailwind-colors-red-100: #fee2e2;
  --tailwind-colors-red-200: #fecaca;
  --tailwind-colors-red-300: #fca5a5;
  --tailwind-colors-red-400: #f87171;
  --tailwind-colors-red-500: #ef4444;
  --tailwind-colors-red-600: #dc2626;
  --tailwind-colors-red-700: #b91c1c;
  --tailwind-colors-red-800: #991b1b;
  --tailwind-colors-red-900: #7f1d1d;
  --tailwind-colors-red-950: #450a0a;
  --tailwind-colors-orange-50: #fff7ed;
  --tailwind-colors-orange-100: #ffedd5;
  --tailwind-colors-orange-200: #fed7aa;
  --tailwind-colors-orange-300: #fdba74;
  --tailwind-colors-orange-400: #fb923c;
  --tailwind-colors-orange-500: #f97316;
  --tailwind-colors-orange-600: #ea580c;
  --tailwind-colors-orange-700: #c2410c;
  --tailwind-colors-orange-800: #9a3412;
  --tailwind-colors-orange-900: #7c2d12;
  --tailwind-colors-orange-950: #431407;
  --tailwind-colors-amber-50: #fffbeb;
  --tailwind-colors-amber-100: #fef3c7;
  --tailwind-colors-amber-200: #fde68a;
  --tailwind-colors-amber-300: #fcd34d;
  --tailwind-colors-amber-400: #fbbf24;
  --tailwind-colors-amber-500: #f59e0b;
  --tailwind-colors-amber-600: #d97706;
  --tailwind-colors-amber-700: #b45309;
  --tailwind-colors-amber-800: #92400e;
  --tailwind-colors-amber-900: #78350f;
  --tailwind-colors-amber-950: #451a03;
  --tailwind-colors-yellow-50: #fefce8;
  --tailwind-colors-yellow-100: #fef9c3;
  --tailwind-colors-yellow-200: #fef08a;
  --tailwind-colors-yellow-300: #fde047;
  --tailwind-colors-yellow-400: #facc15;
  --tailwind-colors-yellow-500: #eab308;
  --tailwind-colors-yellow-600: #ca8a04;
  --tailwind-colors-yellow-700: #a16207;
  --tailwind-colors-yellow-800: #854d0e;
  --tailwind-colors-yellow-900: #713f12;
  --tailwind-colors-yellow-950: #422006;
  --tailwind-colors-lime-50: #f7fee7;
  --tailwind-colors-lime-100: #ecfccb;
  --tailwind-colors-lime-200: #d9f99d;
  --tailwind-colors-lime-300: #bef264;
  --tailwind-colors-lime-400: #a3e635;
  --tailwind-colors-lime-500: #84cc16;
  --tailwind-colors-lime-600: #65a30d;
  --tailwind-colors-lime-700: #4d7c0f;
  --tailwind-colors-lime-800: #3f6212;
  --tailwind-colors-lime-900: #365314;
  --tailwind-colors-lime-950: #1a2e05;
  --tailwind-colors-green-50: #f0fdf4;
  --tailwind-colors-green-100: #dcfce7;
  --tailwind-colors-green-200: #bbf7d0;
  --tailwind-colors-green-300: #86efac;
  --tailwind-colors-green-400: #4ade80;
  --tailwind-colors-green-500: #22c55e;
  --tailwind-colors-green-600: #16a34a;
  --tailwind-colors-green-700: #15803d;
  --tailwind-colors-green-800: #166534;
  --tailwind-colors-green-900: #14532d;
  --tailwind-colors-green-950: #052e16;
  --tailwind-colors-emerald-50: #ecfdf5;
  --tailwind-colors-emerald-100: #d1fae5;
  --tailwind-colors-emerald-200: #a7f3d0;
  --tailwind-colors-emerald-300: #6ee7b7;
  --tailwind-colors-emerald-400: #34d399;
  --tailwind-colors-emerald-500: #10b981;
  --tailwind-colors-emerald-600: #059669;
  --tailwind-colors-emerald-700: #047857;
  --tailwind-colors-emerald-800: #065f46;
  --tailwind-colors-emerald-900: #064e3b;
  --tailwind-colors-emerald-950: #022c22;
  --tailwind-colors-teal-50: #f0fdfa;
  --tailwind-colors-teal-100: #ccfbf1;
  --tailwind-colors-teal-200: #99f6e4;
  --tailwind-colors-teal-300: #5eead4;
  --tailwind-colors-teal-400: #2dd4bf;
  --tailwind-colors-teal-500: #14b8a6;
  --tailwind-colors-teal-600: #0d9488;
  --tailwind-colors-teal-700: #0f766e;
  --tailwind-colors-teal-800: #115e59;
  --tailwind-colors-teal-900: #134e4a;
  --tailwind-colors-teal-950: #042f2e;
  --tailwind-colors-cyan-50: #ecfeff;
  --tailwind-colors-cyan-100: #cffafe;
  --tailwind-colors-cyan-200: #a5f3fc;
  --tailwind-colors-cyan-300: #67e8f9;
  --tailwind-colors-cyan-400: #22d3ee;
  --tailwind-colors-cyan-500: #06b6d4;
  --tailwind-colors-cyan-600: #0891b2;
  --tailwind-colors-cyan-700: #0e7490;
  --tailwind-colors-cyan-800: #155e75;
  --tailwind-colors-cyan-900: #164e63;
  --tailwind-colors-cyan-950: #083344;
  --tailwind-colors-sky-50: #f0f9ff;
  --tailwind-colors-sky-100: #e0f2fe;
  --tailwind-colors-sky-200: #bae6fd;
  --tailwind-colors-sky-300: #7dd3fc;
  --tailwind-colors-sky-400: #38bdf8;
  --tailwind-colors-sky-500: #0ea5e9;
  --tailwind-colors-sky-600: #0284c7;
  --tailwind-colors-sky-700: #0369a1;
  --tailwind-colors-sky-800: #075985;
  --tailwind-colors-sky-900: #0c4a6e;
  --tailwind-colors-sky-950: #082f49;
  --tailwind-colors-blue-50: #eff6ff;
  --tailwind-colors-blue-100: #dbeafe;
  --tailwind-colors-blue-200: #bfdbfe;
  --tailwind-colors-blue-300: #93c5fd;
  --tailwind-colors-blue-400: #60a5fa;
  --tailwind-colors-blue-500: #3b82f6;
  --tailwind-colors-blue-600: #2563eb;
  --tailwind-colors-blue-700: #1d4ed8;
  --tailwind-colors-blue-800: #1e40af;
  --tailwind-colors-blue-900: #1e3a8a;
  --tailwind-colors-blue-950: #172554;
  --tailwind-colors-indigo-50: #eef2ff;
  --tailwind-colors-indigo-100: #e0e7ff;
  --tailwind-colors-indigo-200: #c7d2fe;
  --tailwind-colors-indigo-300: #a5b4fc;
  --tailwind-colors-indigo-400: #818cf8;
  --tailwind-colors-indigo-500: #6366f1;
  --tailwind-colors-indigo-600: #4f46e5;
  --tailwind-colors-indigo-700: #4338ca;
  --tailwind-colors-indigo-800: #3730a3;
  --tailwind-colors-indigo-900: #312e81;
  --tailwind-colors-indigo-950: #1e1b4b;
  --tailwind-colors-violet-50: #f5f3ff;
  --tailwind-colors-violet-100: #ede9fe;
  --tailwind-colors-violet-200: #ddd6fe;
  --tailwind-colors-violet-300: #c4b5fd;
  --tailwind-colors-violet-400: #a78bfa;
  --tailwind-colors-violet-500: #8b5cf6;
  --tailwind-colors-violet-600: #7c3aed;
  --tailwind-colors-violet-700: #6d28d9;
  --tailwind-colors-violet-800: #5b21b6;
  --tailwind-colors-violet-900: #4c1d95;
  --tailwind-colors-violet-950: #2e1065;
  --tailwind-colors-purple-50: #faf5ff;
  --tailwind-colors-purple-100: #f3e8ff;
  --tailwind-colors-purple-200: #e9d5ff;
  --tailwind-colors-purple-300: #d8b4fe;
  --tailwind-colors-purple-400: #c084fc;
  --tailwind-colors-purple-500: #a855f7;
  --tailwind-colors-purple-600: #9333ea;
  --tailwind-colors-purple-700: #7e22ce;
  --tailwind-colors-purple-800: #6b21a8;
  --tailwind-colors-purple-900: #581c87;
  --tailwind-colors-purple-950: #3b0764;
  --tailwind-colors-fuchsia-50: #fdf4ff;
  --tailwind-colors-fuchsia-100: #fae8ff;
  --tailwind-colors-fuchsia-200: #f5d0fe;
  --tailwind-colors-fuchsia-300: #f0abfc;
  --tailwind-colors-fuchsia-400: #e879f9;
  --tailwind-colors-fuchsia-500: #d946ef;
  --tailwind-colors-fuchsia-600: #c026d3;
  --tailwind-colors-fuchsia-700: #a21caf;
  --tailwind-colors-fuchsia-800: #86198f;
  --tailwind-colors-fuchsia-900: #701a75;
  --tailwind-colors-fuchsia-950: #4a044e;
  --tailwind-colors-pink-50: #fdf2f8;
  --tailwind-colors-pink-100: #fce7f3;
  --tailwind-colors-pink-200: #fbcfe8;
  --tailwind-colors-pink-300: #f9a8d4;
  --tailwind-colors-pink-400: #f472b6;
  --tailwind-colors-pink-500: #ec4899;
  --tailwind-colors-pink-600: #db2777;
  --tailwind-colors-pink-700: #be185d;
  --tailwind-colors-pink-800: #9d174d;
  --tailwind-colors-pink-900: #831843;
  --tailwind-colors-pink-950: #500724;
  --tailwind-colors-rose-50: #fff1f2;
  --tailwind-colors-rose-100: #ffe4e6;
  --tailwind-colors-rose-200: #fecdd3;
  --tailwind-colors-rose-300: #fda4af;
  --tailwind-colors-rose-400: #fb7185;
  --tailwind-colors-rose-500: #f43f5e;
  --tailwind-colors-rose-600: #e11d48;
  --tailwind-colors-rose-700: #be123c;
  --tailwind-colors-rose-800: #9f1239;
  --tailwind-colors-rose-900: #881337;
  --tailwind-colors-rose-950: #4c0519;
  --custom-colors-example-50: #ffffff;
  --colors-accent-light: #f4f4f5;
  --colors-accent-dark: #27272a;
  --colors-accent-foreground-light: #18181b;
  --colors-accent-foreground-dark: #fafafa;
  --colors-background-light: #ffffff;
  --colors-background-dark: #09090b;
  --colors-border-light: #e4e4e7;
  --colors-border-dark: #27272a;
  --colors-card-light: #ffffff;
  --colors-card-dark: #09090b;
  --colors-card-foreground-light: #09090b;
  --colors-card-foreground-dark: #fafafa;
  --colors-destructive-light: #dc2626;
  --colors-destructive-dark: #7f1d1d;
  --colors-destructive-foreground-light: #fef2f2;
  --colors-destructive-foreground-dark: #fef2f2;
  --colors-foreground-light: #18181b;
  --colors-foreground-dark: #fafafa;
  --colors-input-light: #e4e4e7;
  --colors-input-dark: #27272a;
  --colors-muted-light: #f4f4f5;
  --colors-muted-dark: #27272a;
  --colors-muted-foreground-light: #71717a;
  --colors-muted-foreground-dark: #a1a1aa;
  --colors-popover-light: #ffffff;
  --colors-popover-dark: #09090b;
  --colors-popover-foreground-light: #09090b;
  --colors-popover-foreground-dark: #fafafa;
  --colors-primary-light: #4338ca;
  --colors-primary-dark: #e0e7ff;
  --colors-primary-foreground-light: #eef2ff;
  --colors-primary-foreground-dark: #1e1b4b;
  --colors-ring-light: #18181b;
  --colors-ring-dark: #d4d4d8;
  --colors-secondary-light: #f4f4f5;
  --colors-secondary-dark: #27272a;
  --colors-secondary-foreground-light: #18181b;
  --colors-secondary-foreground-dark: #fafafa;
  --colors-chart-1-light: #2a9d90;
  --colors-chart-1-dark: #2662d9;
  --colors-chart-2-light: #e76e50;
  --colors-chart-2-dark: #e23670;
  --colors-chart-3-light: #274754;
  --colors-chart-3-dark: #e88c30;
  --colors-chart-4-light: #e8c468;
  --colors-chart-4-dark: #af57db;
  --colors-chart-5-light: #f4a462;
  --colors-chart-5-dark: #2eb88a;
  --colors-sidebar-background-light: #ffffff;
  --colors-sidebar-background-dark: #09090b;
  --colors-sidebar-foreground-light: #18181b;
  --colors-sidebar-foreground-dark: #fafafa;
  --colors-sidebar-primary-light: #4338ca;
  --colors-sidebar-primary-dark: #e0e7ff;
  --colors-sidebar-primary-foreground-light: #eef2ff;
  --colors-sidebar-primary-foreground-dark: #1e1b4b;
  --colors-sidebar-accent-light: #f4f4f5;
  --colors-sidebar-accent-dark: #27272a;
  --colors-sidebar-accent-foreground-light: #18181b;
  --colors-sidebar-accent-foreground-dark: #fafafa;
  --colors-sidebar-border-light: #e4e4e7;
  --colors-sidebar-border-dark: #27272a;
  --colors-sidebar-ring-light: #18181b;
  --colors-sidebar-ring-dark: #d4d4d8;
  --border-radius-sm: 2px;
  --border-radius-default: 6px;
  --border-radius-md: 6px;
  --border-radius-lg: 8px;
  --border-radius-xl: 12px;
  --border-radius-full: 9999px;

  --typography-base-sizes-extra-small-font-size: 12px;
  --typography-base-sizes-extra-small-line-height: 16px;
  --typography-base-sizes-small-font-size: 14px;
  --typography-base-sizes-small-line-height: 20px;
  --typography-base-sizes-base-font-size: 16px;
  --typography-base-sizes-base-line-height: 24px;
  --typography-base-sizes-large-font-size: 18px;
  --typography-base-sizes-large-line-height: 28px;
  --typography-base-sizes-xlarge-font-size: 20px;
  --typography-base-sizes-xlarge-line-height: 28px;
  --typography-base-sizes-2x-large-font-size: 24px;
  --typography-base-sizes-2x-large-line-height: 32px;
  --typography-base-sizes-3x-large-font-size: 30px;
  --typography-base-sizes-3x-large-line-height: 36px;
  --typography-base-sizes-4x-large-font-size: 36px;
  --typography-base-sizes-4x-large-line-height: 40px;

  --typography-typography-components-h1-font-size: 36px;
  --typography-typography-components-h1-font-size-lg: 48px;
  --typography-typography-components-h1-line-height: 40px;
  --typography-typography-components-h1-font-weight: 800;
  --typography-typography-components-h1-letter-spacing: -0.4px;

  --typography-typography-components-h2-font-size: 30px;
  --typography-typography-components-h2-line-height: 36px;
  --typography-typography-components-h2-font-weight: 600;
  --typography-typography-components-h2-letter-spacing: -0.4px;

  --typography-typography-components-h3-font-size: 24px;
  --typography-typography-components-h3-line-height: 32px;
  --typography-typography-components-h3-font-weight: 600;
  --typography-typography-components-h3-letter-spacing: -0.4px;

  --typography-typography-components-h4-font-size: 20px;
  --typography-typography-components-h4-line-height: 28px;
  --typography-typography-components-h4-font-weight: 600;
  --typography-typography-components-h4-letter-spacing: -0.4px;

  --typography-typography-components-p-font-size: 16px;
  --typography-typography-components-p-line-height: 28px;
  --typography-typography-components-p-font-weight: 400;
  --typography-typography-components-p-letter-spacing: 0px;

  --typography-typography-components-blockquote-font-size: 16px;
  --typography-typography-components-blockquote-line-height: 24px;
  --typography-typography-components-blockquote-font-weight: 400;
  --typography-typography-components-blockquote-letter-spacing: 0px;

  --typography-typography-components-list-font-size: 16px;
  --typography-typography-components-list-line-height: 28px;
  --typography-typography-components-list-font-weight: 400;
  --typography-typography-components-list-letter-spacing: 0px;

  --typography-typography-components-inline-code-font-size: 14px;
  --typography-typography-components-inline-code-line-height: 20px;
  --typography-typography-components-inline-code-font-weight: 600;
  --typography-typography-components-inline-code-letter-spacing: 0px;

  --typography-typography-components-lead-font-size: 20px;
  --typography-typography-components-lead-line-height: 28px;
  --typography-typography-components-lead-font-weight: 400;
  --typography-typography-components-lead-letter-spacing: 0px;

  --typography-typography-components-large-font-size: 18px;
  --typography-typography-components-large-line-height: 28px;
  --typography-typography-components-large-font-weight: 600;
  --typography-typography-components-large-letter-spacing: 0px;

  --typography-typography-components-small-font-size: 14px;
  --typography-typography-components-small-line-height: 14px;
  --typography-typography-components-small-font-weight: 500;
  --typography-typography-components-small-letter-spacing: 16px;

  --typography-typography-components-table-font-size: 16px;
  --typography-typography-components-table-font-weight: 400;
  --typography-typography-components-table-font-weight-bold: 700;
  --typography-typography-components-table-letter-spacing: 0px;

  --base-accent: #f4f4f5;
  --base-accent-foreground: #18181b;
  --base-background: #ffffff;
  --base-border: #e4e4e7;
  --base-card: #ffffff;
  --base-card-foreground: #09090b;
  --base-destructive: #dc2626;
  --base-destructive-foreground: #fef2f2;
  --base-foreground: #18181b;
  --base-input: #e4e4e7;
  --base-muted: #f4f4f5;
  --base-muted-foreground: #71717a;
  --base-popover: #ffffff;
  --base-popover-foreground: #09090b;
  --base-primary: #4338ca;
  --base-primary-foreground: #eef2ff;
  --base-ring: #18181b;
  --base-ring-offset: #ffffff;
  --base-secondary: #f4f4f5;
  --base-secondary-foreground: #18181b;
  --base-chart-1: #2a9d90;
  --base-chart-2: #e76e50;
  --base-chart-3: #274754;
  --base-chart-4: #e8c468;
  --base-chart-5: #f4a462;
  --base-sidebar-background: #ffffff;
  --base-sidebar-foreground: #18181b;
  --base-sidebar-primary: #4338ca;
  --base-sidebar-primary-foreground: #eef2ff;
  --base-sidebar-accent: #f4f4f5;
  --base-sidebar-accent-foreground: #18181b;
  --base-sidebar-border: #e4e4e7;
  --base-sidebar-ring: #18181b;
  --alpha-white-95: rgba(255, 255, 255, 0.95);
  --alpha-white-90: rgba(255, 255, 255, 0.9);
  --alpha-white-80: rgba(255, 255, 255, 0.8);
  --alpha-white-70: rgba(255, 255, 255, 0.7);
  --alpha-white-60: rgba(255, 255, 255, 0.6);
  --alpha-white-50: rgba(255, 255, 255, 0.5);
  --alpha-white-40: rgba(255, 255, 255, 0.4);
  --alpha-white-30: rgba(255, 255, 255, 0.3);
  --alpha-white-20: rgba(255, 255, 255, 0.2);
  --alpha-white-10: rgba(255, 255, 255, 0.1);
  --alpha-white-5: rgba(255, 255, 255, 0.05);
  --alpha-black-95: rgba(9, 9, 11, 0.95);
  --alpha-black-90: rgba(9, 9, 11, 0.9);
  --alpha-black-80: rgba(9, 9, 11, 0.8);
  --alpha-black-70: rgba(9, 9, 11, 0.7);
  --alpha-black-60: rgba(9, 9, 11, 0.6);
  --alpha-black-50: rgba(9, 9, 11, 0.5);
  --alpha-black-40: rgba(9, 9, 11, 0.4);
  --alpha-black-30: rgba(9, 9, 11, 0.3);
  --alpha-black-20: rgba(9, 9, 11, 0.2);
  --alpha-black-10: rgba(9, 9, 11, 0.1);
  --alpha-black-5: rgba(9, 9, 11, 0.05);

  /* Effect styles */
  --shadow--sm: 0px 1px 2px rgba(0, 0, 0, 0.05);
  --shadow--base: 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  --shadow--md: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  --shadow--lg: 0px 4px 6px rgba(0, 0, 0, 0.05),
    0px 10px 15px rgba(0, 0, 0, 0.1);
  --shadow--xl: 0px 10px 10px rgba(0, 0, 0, 0.04),
    0px 20px 25px rgba(0, 0, 0, 0.1);
  --shadow--2xl: 0px 25px 50px rgba(0, 0, 0, 0.25);
  --shadow--inner: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
  --blur--none-shadows: ;
  --blur--none-blur: blur(0px);
  --blur--sm-shadows: ;
  --blur--sm-blur: blur(4px);
  --blur--base-shadows: ;
  --blur--base-blur: blur(8px);
  --blur--md-shadows: ;
  --blur--md-blur: blur(12px);
  --blur--lg-shadows: ;
  --blur--lg-blur: blur(16px);
  --blur--xl-shadows: ;
  --blur--xl-blur: blur(24px);
  --blur--2xl-shadows: ;
  --blur--2xl-blur: blur(40px);
  --blur--3xl-shadows: ;
  --blur--3xl-blur: blur(64px);
  --backdrop-blur--none: ;
  --backdrop-blur--sm: ;
  --backdrop-blur--base: ;
  --backdrop-blur--md: ;
  --backdrop-blur--lg: ;
  --backdrop-blur--xl: ;
  --backdrop-blur--2xl: ;
  --backdrop-blur--3xl: ;
}