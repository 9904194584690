.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: transparent;
  height: 4px;
  overflow: hidden;
}

.progressBar {
  position: relative;
  width: 100%;
  height: 100%;
}

.progressIndicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--base-primary);
  animation: indeterminate 2s infinite;
}

@keyframes indeterminate {
  0% {
    left: -100%;
    width: 30%;
  }
  50% {
    left: 25%;
    width: 50%;
  }
  100% {
    left: 100%;
    width: 30%;
  }
}
