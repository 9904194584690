@tailwind base;
@tailwind components;
@tailwind utilities;

@import "helpers";

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: var(--font-inter);
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

pre {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: inherit;
  text-align: inherit;
  cursor: pointer;
}

input,
textarea {
  padding: var(--spacing-2) var(--spacing-3);
  display: flex;
  align-items: center;
  background: var(--base-background);
  border: var(--border-width-border-1) solid var(--base-input);
  border-radius: var(--border-radius-md);
  height: var(--height-h-10);
  color: var(--base-foreground);
  font-weight: var(--font-weight-normal);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);

  --tw-ring-color: var(--base-ring);

  &:disabled {
    opacity: var(--opacity-opacity-50);
    cursor: not-allowed;
  }

  &::placeholder {
    color: var(--base-muted-foreground);
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-small-font-size);
    line-height: var(--typography-base-sizes-small-line-height);
  }
}

.field-label {
  font-weight: var(--font-weight-medium);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-typography-components-small-line-height);
  color: var(--base-foreground);
}

.field-description,
.field-error {
  font-weight: var(--font-weight-normal);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);
  color: var(--base-muted-foreground);
}

.field-error {
  color: var(--base-destructive);
}

.icon {
  color: inherit;

  svg {
    width: var(--width-w-4);
    height: var(--height-h-4);
  }
}

.ring-black {
  --tw-ring-color: var(--base-ring);
}

.otpInput {
  color: transparent !important;

  &::selection {
    color: transparent !important;
  }
}

.otpSlot {
  outline: solid var(--base-ring);
  outline-width: 2px;
  outline-offset: -2px;
}

.iti {
  display: flex !important;
  gap: var(--spacing-3);

  .iti__country-container {
    position: relative;
    display: flex;
    padding: 0;

    button {
      height: 100% !important;
      display: flex;
      justify-content: center;
      width: var(--width-w-16);
      height: var(--height-h-10);
      border: 1px solid var(--base-input);
      border-radius: var(--border-radius-md);

      &:focus,
      &:active,
      &[aria-expanded="true"] {
        outline: 2px solid var(--base-ring) !important;
        outline-offset: 2px !important;
      }

      &:disabled {
        opacity: var(--opacity-opacity-50);
        outline: none !important;
        cursor: not-allowed;
      }

      .iti__selected-country-primary {
        width: 100%;
        display: flex;
        justify-content: center;
        border-radius: inherit;
        background-color: var(--base-background) !important;

        &:hover {
          background-color: var(--base-background) !important;
        }

        .iti__flag {
          // border-radius: 50%;
          // transform: scale(1.25);
        }

        .iti__arrow {
          margin-left: var(--spacing-1);
          background: url("../../public/media/icons/chevronDown.svg");
          width: 16px;
          height: 16px;
          border: none;

          &.iti__arrow--up {
            transform: rotate(180deg);
          }
        }
      }
    }

    .iti__dropdown-content {
      top: calc(var(--height-h-10));
      margin-top: var(--spacing-0-5);
      border-color: var(--base-border);
      background-color: var(--base-popover);
      border-radius: var(--border-radius-md);
      box-shadow: var(--shadow--md);
      width: 292px !important;

      .iti__search-input {
        padding: 0 var(--spacing-2) 0 32px;
        margin: var(--spacing-1) 0;
        height: var(--height-h-9);
        border-bottom: 1px solid var(--base-muted);
        outline: none;
        background: url("../../public/media/icons/search.svg") no-repeat;
        background-position: var(--spacing-2) center;
        font-weight: var(--font-weight-normal);
        font-size: var(--typography-base-sizes-small-font-size);
        line-height: var(--typography-base-sizes-small-line-height);
        color: var(--base-foreground);

        &::placeholder {
          color: var(--base-muted-foreground);
        }
      }

      .iti__country-list {
        .iti__country {
          padding: var(--spacing-1-5) var(--spacing-3);
          font-weight: var(--font-weight-normal);
          font-size: var(--typography-base-sizes-small-font-size);
          line-height: var(--typography-base-sizes-small-line-height);
          color: var(--base-popover-foreground);

          .iti__flag,
          .iti__country-name {
            margin-right: var(--spacing-2);
          }

          .iti__dial-code {
            font-weight: var(--font-weight-normal);
            font-size: var(--typography-base-sizes-small-font-size);
            line-height: var(--typography-base-sizes-small-line-height);
            color: var(--base-popover-foreground);
            opacity: var(--opacity-opacity-60);
          }
        }
      }
    }
  }

  .iti__tel-input {
    flex: 1;
    border: 1px solid var(--base-input);
    padding: var(--spacing-3) var(--spacing-3-5) !important;
    border-radius: var(--border-radius-md);

    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-small-font-size);
    line-height: var(--typography-base-sizes-small-line-height);
    color: var(--base-foreground);

    &::placeholder {
      color: var(--base-muted-foreground);
    }

    &:focus {
      outline: 2px solid var(--base-ring);
      outline-offset: 2px;
    }
  }
}

.int-error {
  .iti__tel-input {
    border-color: var(--base-destructive) !important;
  }
}

.notes-container {
  height: 100dvh;
  width: 100%;
  display: flex;
}

.sidebarBlur {
  background-color: var(--alpha-black-50);
}

.sidebarContainer {
  padding: 0;
  width: max-content;

  &.open .sidebar {
    transform: translateX(0);
  }

  .sidebar {
    width: 256px;
    background-color: var(--base-sidebar-background);
    border-right: 1px solid var(--base-border);
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    overflow: hidden;
  }
}

.sidebar-desktop {
  background-color: var(--base-sidebar-background);
}

.dialog-title {
  color: var(--base-foreground);
  font-weight: var(--font-weight-semibold);
  font-size: var(--typography-base-sizes-large-font-size);
  line-height: var(--typography-base-sizes-large-font-size);
}

.dialog-description {
  color: var(--base-muted-foreground);
  font-weight: var(--font-weight-normal);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);
}


.select-trigger {
  border: 1px solid var(--base-border);
}

.select-item {
  &:hover, &:focus {
    background-color: var(--base-accent);
  }
}

.calendar-nav-button {
  border: 1px solid var(--base-input);

  &:disabled {
    display: none;
  }
}

.calendar-head-cell {
  color: var(--base-muted-foreground);
}

.calendar-cell {
  border-radius: var(--border-radius-md);
  color: var(--base-foreground);
  font-weight: var(--font-weight-normal);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);

  &:hover {
    background-color: var(--base-accent);
  }

  &-selected {
    background-color: var(--base-primary);
    color: var(--base-primary-foreground);
  }

  &-outside {
    color: var(--base-muted-foreground);

    &:hover {
      background-color: var(--base-accent);
      color: var(--base-foreground);
    }
  }
}

.calendar-caption-label {
  color: var(--base-foreground);
}
