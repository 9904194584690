.title {
  font-weight: var(--font-weight-semibold);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);
}

.description {
  font-weight: var(--font-weight-normal);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);
}
